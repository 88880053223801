import React from "react";
import styled from "styled-components";
import image1 from "../images/defaultImg.jpg";

const BlogPreview2El = styled.div`
  width: 33.33%;
  padding: 1%;
  position: relative;

  img {
    max-width: 100%;
    min-height: 300px;
    object-fit: cover;
    margin-bottom: 0.3rem;
  }
  .detail-container {
    text-align: center;
    padding: 0 20px 45px;

    a {
      text-decoration: none;
      color: inherit;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #62c3d4;
      }

      .entry-title {
        font-size: 20px;
        margin: -3px 0;
        font-family: "Karla";
        font-weight: normal;
        text-transform: capitalize;
      }
    }
    h5 {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 1px;
      width: max-content;
      background: white;
      margin: auto;
      position: relative;
      top: -4px;
      padding: 2px 29px;
    }
  }

  @media only screen and (max-width: 939px) {
    width: 50%;
    padding: 3%;
  }

  @media only screen and (max-width: 720px) {
    width: 100%;
    padding: 4%;
  }
`;

const BlogPreview2 = ({ blogpost }) => {
  console.log("blogpost: ", blogpost);
  return (
    <BlogPreview2El>
      <a href={`/blogpost/${blogpost && blogpost.slug}`}>
        <img
          alt="blog-post-image1"
          src={blogpost && blogpost.image && blogpost.image.file.url}
        />
      </a>
      <div className="detail-container">
        <h5 className="h5-text">{blogpost && blogpost.category}</h5>
        <a href={`/blogpost/${blogpost && blogpost.slug}`}>
          <h3 className="entry-title h3-text">{blogpost && blogpost.title}</h3>
        </a>
      </div>
    </BlogPreview2El>
  );
};

export default BlogPreview2;
