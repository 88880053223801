import React from "react";
import styled from "styled-components";
import YoutubeFrame from "./common/YoutubeFrame";
import ViewMoreButton from "../components/ViewMoreButton";
import videoSrc from "../images/workout.mp4";

const WorkoutOfTheWeekEl = styled.div`
  .section-container {
    background: #ffffff;
    width: 100%;
    padding: 0% 8%;
    margin-top: 42px;
    margin-bottom: 36px;

    .content-wrapper {
      display: flex;
      padding-bottom: 30px;
    }

    .wow-video-wrapper {
      width: 50%;
    }

    .text-wrapper {
      width: 50%;
      padding: 8% 4% 5% 4%;

      .h4-text {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 24px;
      }
      .lead-text {
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 95%;
        color: #3c2a3a;
      }
      .paragraph-text {
        margin-bottom: 32px;
      }
      .viewmore-button-wrapper {
        display: flex;
        justify-content: initial;
      }
    }
  }

  @media only screen and (max-width: 874px) {
    .section-container {
      padding: 0% 5%;
      margin-top: 64px;
      margin-bottom: 48px;

      .content-wrapper {
        flex-direction: column;

        .wow-video-wrapper {
          width: 100%;
        }

        .text-wrapper {
          width: 100%;
          text-align: center;
          padding: 5% 0% 0% 0%;

          .viewmore-button-wrapper {
            justify-content: center;
          }
        }
      }
    }
  }
`;

const WorkoutOfTheWeek = () => {
  return (
    <WorkoutOfTheWeekEl>
      <div className="section-container">
        <div className="content-wrapper">
          <video className="wow-video-wrapper" playsInline muted autoPlay loop>
            <source src={videoSrc} type="video/mp4" />
          </video>

          <div className="text-wrapper">
            <div className="h4-text">A new workout every week</div>
            <div className="lead-text">
              Burn fat, get stronger, get body confident!
            </div>
            <div className="paragraph-text">
              Get a new strength training and cardio workout routine every week
              to sculpt and strengthen your beautiful body. Based on muscle
              groups that incorporate strength, cardio, core and flexibility for
              best results.
            </div>
            <div className="viewmore-button-wrapper">
              <ViewMoreButton title="Start Workout" link="/workouts" />
            </div>
          </div>
        </div>
      </div>
    </WorkoutOfTheWeekEl>
  );
};

export default WorkoutOfTheWeek;
