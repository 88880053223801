import React from "react";
import styled from "styled-components";

const BlogPreviewWrapperEl = styled.div`
  padding-bottom: 3%;
  .blog-preview-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
  }
`;

const BlogPreviewWrapper = ({ children }) => {
  return (
    <BlogPreviewWrapperEl>
      <div className="blog-preview-container">{children}</div>
    </BlogPreviewWrapperEl>
  );
};

export default BlogPreviewWrapper;
