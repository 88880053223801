import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogPreviewWrapper from "../components/BlogPreviewWrapper";
import SectionDivider from "../components/SectionDivider";
import BlogPreview2 from "../components/BlogPreview2";
import ViewMoreButton from "../components/ViewMoreButton";
import AboutYoutubeSection from "../components/AboutYoutubeSection";
import styled from "styled-components";
import { graphql } from "gatsby";
import WorkoutOfTheWeek from "../components/WorkoutOfTheWeek";

const IndexEl = styled.div`
  max-width: 1200px;
  margin: auto;
  .h4-text {
    font-family: "Karla";
    font-size: 1.4rem;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  .blog-view-more-button-wrapper {
    width: 100%;
  }
`;

const IndexPage = ({ data }) => (
  <Layout
    heroText={"fitness & Lifestyle"}
    heroSubText={
      "Empowering women to invest in their wellbeing, while having as much fun as possible"
    }
  >
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <IndexEl>
      {/* <SectionDivider title="Featured" paddingLeft="1%" paddingRight="1%" /> */}
      {/* <h4 className="h4-text">on the blog</h4> */}
      <BlogPreviewWrapper>
        {data.allContentfulBlogPost.edges.map(blogpost => {
          return (
            <BlogPreview2 key={blogpost.node.id} blogpost={blogpost.node} />
          );
        })}

        <div className="blog-view-more-button-wrapper">
          <ViewMoreButton title="read more posts" />
        </div>
      </BlogPreviewWrapper>
    </IndexEl>
    <WorkoutOfTheWeek />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    allContentfulBlogPost(
      sort: { order: DESC, fields: [createdAt] }
      limit: 12
    ) {
      edges {
        node {
          id
          title
          slug
          image {
            file {
              url
            }
          }
          category
        }
      }
    }
  }
`;
